<template>
    <div>
        <div v-if="!isAuthenticated">
            <h5>Herzlich Willkommen beim Kinderkleiderbasar der BRK Bereitschaftsjugend Neu-Ulm.</h5>

            <p v-show="true">Der nächster Kinderkleiderbasar ist am <b>16.03.2025</b>.</p>

            <p v-show="true">Mit unserem Anmeldeformular bekommen Sie eine Verkaufsnummer oder können sich als Helfer eintragen. Gerne dürfen Sie uns Ihre Wunschverkaufsnummer mitteilen und wir versuchen diese für Sie zu reservieren. Füllen Sie einfach unser Anmeldeformular aus und erhalten Sie alle weiteren Informationen sowie Ihre Etiketten. Da unser Kinderkleiderbasar zweimal im Jahr von vielen freiwilligen Helfern organisiert wird, freuen wir uns jederzeit über Unterstützung. Bei uns findet jeder eine Aufgabe die ihm Spaß macht. Wir freuen uns auf Sie.</p>

            <b-alert variant="success" :show="success">
                <p>
                    <b>Vielen Dank für Ihre Anmeldung!</b>
                </p>

                <p>
                    Sobald wir Ihre Anmeldung geprüft haben, erhalten Sie eine E-Mail mit weiteren Basarinformationen und Ihrer Verkaufsnummer.
                </p>
            </b-alert>

            <b-alert variant="danger" :show="error">
                <p>
                    <b>Es ist ein Fehler aufgetreten!</b>
                </p>

                <ul>
                    <li v-for="(e, i) in errors" :key="i">{{ e }}</li>
                </ul>
            </b-alert>

            <b-form @submit="onSubmit" v-if="show" v-show="true">
                <b-form-group label="Nachname:" label-for="lastnameInput">
                    <b-form-input id="lastnameInput" type="text" v-model="form.lastname" required placeholder="Ihr Nachname" />
                </b-form-group>

                <b-form-group label="Vorname:" label-for="firstnameInput">
                    <b-form-input id="firstnameInput" type="text" v-model="form.firstname" required placeholder="Ihr Vorname" />
                </b-form-group>

                <b-form-group label="E-Mail Adresse:" label-for="mailInput">
                    <b-form-input id="mailInput" type="email" v-model="form.mail" required placeholder="Ihre E-Mail Adresse" />
                </b-form-group>

                <b-form-group label="Telefonnummer:" label-for="phoneInput">
                    <b-form-input id="phoneInput" type="tel" v-model="form.phone" required placeholder="Ihre Telefonnummer" />
                </b-form-group>

                <b-form-group label="Wunsch Verkaufsnummer:" label-for="numberInput">
                    <b-form-input id="numberInput" type="number" v-model="form.desiredNumber" required placeholder="Ihre Wunsch Verkaufsnummer" />
                </b-form-group>

                <b-form-group>
                    <b-form-checkbox-group v-model="form.consent">
                        <b-form-checkbox value="mail">Ich möchte per Mail über Informationen (Abholzeiten, Verkaufszeiten, etc.) informiert werden?</b-form-checkbox><br />
                    </b-form-checkbox-group>
                    <b-form-checkbox-group v-model="form.consent">
                        <b-form-checkbox value="next">Ich bin damit Einverstanden, dass meine Daten für den nächsten Basar gespeichert werden.</b-form-checkbox><br />
                    </b-form-checkbox-group>
                </b-form-group>

                <b-form-group label="Helfen Sie mit!">
                    <p>Ich habe Interesse an folgenden Zeiten zu helfen (gerne mehrere ankreuzen):</p>

                    <b-form-checkbox-group v-model="form.help">
                        <b-form-checkbox value="aufbau"><b>Abgabe und Aufbau:</b> Samstag, 15.03.2025 13:00 Uhr - ca. 20:00 Uhr</b-form-checkbox><br />
                        <b-form-checkbox value="verkauf"><b>Verkaufstag:</b> Sonntag, 16.03.2025 13:00 Uhr - 16:00 Uhr</b-form-checkbox><br />
                        <b-form-checkbox value="abbau"><b>Abbau und Rückgabe:</b> Sonntag, 16.03.2025 16:00 Uhr - ca. 19:00 Uhr</b-form-checkbox>
                    </b-form-checkbox-group>
                </b-form-group>

                <p><small>Mit der Anmeldung akzeptieren Sie unsere <router-link to="/privacy" target="_blank">Datenschutzinformationen</router-link>.</small></p>

                <b-button type="submit" variant="primary">Absenden</b-button>
            </b-form>
        </div>

        <div v-if="isAuthenticated">
            <b-card title="Der nächste Kinderkleiderbasar ist am 16.03.2025" class="mb-5" v-if="confirm.previousNumber && confirm.previousNumber > 0 && confirm.number === ''">
                <p class="card-text">
                    Bitte überprüfen und bestätigen Sie Ihre Daten, wenn Sie an dem kommenden Basar am 16.03.2025 in Ludwigsfeld teilnehmen möchten.
                </p>

                <b-alert variant="danger" :show="confirmError">
                    <p>
                        <b>Es ist ein Fehler aufgetreten!</b>
                    </p>

                    <ul>
                        <li v-for="(e, i) in errors" :key="i">{{ e }}</li>
                    </ul>
                </b-alert>

                <b-form @submit="onConfirm">
                    <b-form-group label="Nachname:" label-for="lastnameInputConfirm">
                        <b-form-input id="lastnameInputConfirm" type="text" v-model="confirm.lastname" required placeholder="Ihr Nachname" />
                    </b-form-group>

                    <b-form-group label="Vorname:" label-for="firstnameInputConfirm">
                        <b-form-input id="firstnameInputConfirm" type="text" v-model="confirm.firstname" required placeholder="Ihr Vorname" />
                    </b-form-group>

                    <b-form-group label="E-Mail Adresse:" label-for="mailInputConfirm">
                        <b-form-input id="mailInputConfirm" type="email" v-model="confirm.mail" readonly placeholder="Ihre E-Mail Adresse" />
                    </b-form-group>

                    <b-form-group label="Telefonnummer:" label-for="phoneInputConfirm">
                        <b-form-input id="phoneInputConfirm" type="tel" v-model="confirm.phone" required placeholder="Ihre Telefonnummer" />
                    </b-form-group>

                    <b-form-group label="Verkaufsnummer:" label-for="numberInputConfirm">
                        <b-form-input id="numberInputConfirm" type="number" v-model="confirm.previousNumber" readonly placeholder="Verkaufsnummer" />
                    </b-form-group>

                    <b-form-group>
                        <b-form-checkbox-group v-model="confirm.consent">
                            <b-form-checkbox value="mail">Ich möchte per Mail über Informationen (Abholzeiten, Verkaufszeiten, etc.) informiert werden?</b-form-checkbox><br />
                        </b-form-checkbox-group>
                        <b-form-checkbox-group v-model="confirm.consent">
                            <b-form-checkbox value="next">Ich bin damit Einverstanden, dass meine Daten für den nächsten Basar gespeichert werden.</b-form-checkbox><br />
                        </b-form-checkbox-group>
                    </b-form-group>

                    <b-form-group label="Helfen Sie mit!">
                        <p>Ich habe Interesse an folgenden Zeiten zu helfen (gerne mehrere ankreuzen):</p>

                        <b-form-checkbox-group v-model="confirm.help">
                            <b-form-checkbox value="aufbau"><b>Abgabe und Aufbau:</b> Samstag, 15.03.2025 13:00 Uhr - ca. 20:00 Uhr</b-form-checkbox><br />
                            <b-form-checkbox value="verkauf"><b>Verkaufstag:</b> Sonntag, 16.03.2025 13:00 Uhr - 16:00 Uhr</b-form-checkbox><br />
                            <b-form-checkbox value="abbau"><b>Abbau und Rückgabe:</b> Sonntag, 16.03.2025 16:00 Uhr - ca. 19:00 Uhr</b-form-checkbox>
                        </b-form-checkbox-group>
                    </b-form-group>

                    <p><small>Mit der Anmeldung akzeptieren Sie unsere <router-link to="/privacy" target="_blank">Datenschutzinformationen</router-link>.</small></p>

                    <b-button type="submit" variant="primary">Daten und Teilnahme bestätigen</b-button>
                </b-form>
            </b-card>

            <h5>Herzlich Willkommen,</h5>

            <p>am <b>16.03.2025</b> findet wieder unser Kinderkleiderbasar in Ludwigsfeld in der Mehrzweckhalle Karlsbaderstraße 23 (bei der Erich-Kästner-Schule) statt.</p>

            <p v-if="confirm.number && confirm.number !== '' && confirm.previousNumber"><b>Ihre Verkaufsnummer {{ confirm.number }} ist bestätigt.</b></p>

            <p>Sie können sich hier Ihre individuellen Etiketten mit Ihrer Verkaufsnummer zur Auszeichnung Ihrer Ware drucken.</p>

            <p><b>Handgeschriebene Etiketten ohne Barcode können nicht mehr angenommen werden (außer Preise über 100€)!</b></p>

            <p><u>Wie funktionieren die Barcode-Etiketten?</u></p>

            <ol>
                <li>Bitte drucken Sie sich die benötigten Etiketten auf <b>dickes weißes</b> Papier aus. Sie können die Etiketten auch auf normales Papier drucken und auf Pappe kleben (hier eignen sich auch z.B. alte Cornflakes Schachteln etc.)</li>
                <li><b>Alternativ, können Sie für den Umkostenbeitrag von 5 € die Etiketten bei uns bestellen und per Post nach Hause geliefert bekommen, bzw. für 3,50 € diese bei uns abholen.</b></li>
                <li>Den benötigten Preis können Sie dann einfach ausschneiden und die Größe ergänzen.</li>
                <li>Dann befestigen Sie das Etikett fest am Artikel (nicht kleben).</li>
                <li>Fertig - einfach und schnell!</li>
            </ol>

            <p>Schwangere (mit Mutterpass) und Rollstuhlfahrer dürfen bei uns, gemeinsam mit maximal einer Begleitperson, ab 13.30 Uhr einkaufen.</p>

            <p>Wir wünschen Ihnen viel Spaß bei unserem Basar.<br />Das gesamte Basarteam</p>

            <p>
                Abgabe: Samstag, 15.03.2025 14:00 - 17:00 Uhr<br />
                Verkauf: Sonntag, 16.03.2025 14:00 - 16:00 Uhr<br />
                Abholung: Sonntag, 16.03.2025 18:00 - 18:30 Uhr
            </p>

            <p>Ort: Mehrzweckhalle Ludwigsfeld, Karlsbaderstraße 23,89231 Neu-Ulm</p>

            <p>
                <b>Verkauft wird:</b>
                <ol>
                    <li>Saisonale Kinderbekleidung bis Größe 176 (Frühjahr und Sommer)</li>
                    <li>Kinderschuhe, 5 Paar in gutem Zustand</li>
                    <li>Vollständige, ganze Spielsachen</li>
                    <li>Kinderbücher oder Ratgeber für Eltern</li>
                    <li>Schwangerschaftsbekleidung</li>
                    <li>Fahrräder, Kinderfahrzeuge, Kinderwagen, Autositze, …</li>
                </ol>
            </p>

            <p>
                <b>Bitte beachten Sie folgendes:</b>
                <ol>
                    <li>Geben Sie nur Ware ab, die Sie selbst noch kaufen würden.</li>
                    <li>Defekte Artikel erhalten Sie zurück.</li>
                    <li>Befestigen Sie Ihre Etiketten sicher an der Ware, ohne diese zu beschädigen.</li>
                    <li>Bitte verzichten Sie auf Nadeln, damit sich niemand verletzt.</li>
                    <li>Bitte beschriften Sie Ihren Karton/Tasche ebenfalls mit der Verkaufsnummer.</li>
                </ol>
            </p>

            <p>Damit möglichst viele Verkäufer*innen eine Chance erhalten Ihre Ware zu verkaufen können pro Verkäufer*in nur 2 Kartons, Körbe oder Taschen abgegeben werden.</p>

            <b-card title="Etikettenbogen generieren" class="mb-4" v-if="confirm.previousNumber && confirm.number && confirm.number !== ''">
                <b-alert variant="success" :show="order.success && order.url !== ''">
                    <p>
                        <b>Etikettenbogen wurde erfolgreich generiert!</b>
                    </p>

                    <b-button type="submit" variant="primary" :href="order.url" target="_blank" download="brk-neu-ulm_kibaz-etiketten.pdf">
                        <font-awesome-icon :icon="faFilePdf" />
                        Etikettenbogen herunterladen
                    </b-button>
                </b-alert>
                <b-alert variant="success" :show="order.success && order.url === ''">
                    <p>
                        <b>Ihre Bestellung ist eingegangen!</b>
                    </p>

                    <p v-show="order.mode === 'deliver'">
                        Sobald Ihre Etiketten gedruckt sind, werden wir diese per Post an die von Ihnen angegebene Adresse versenden.
                    </p>

                    <p v-show="order.mode === 'pickup'">
                        Sie können Ihre Bestellung zu den folgenden Öffnungszeiten abholen:<br /><br />

                        <small>
                            Bayerisches Rotes Kreuz Kreisverband Neu-Ulm<br />
                            Pfaffenweg 42, 89231 Neu-Ulm<br /><br />
                            Öffnungszeiten: Mo-Do 08:00-12:00 und 13:00-16:00<br />
                            Freitag 08:00-12:00
                        </small>
                    </p>
                </b-alert>
                <b-form @submit="onOrder" v-show="!order.success">
                    <b-card-group deck class="mb-3">
                        <b-card>
                            <b-row v-for="type in order.types.slice(0, 15)" :key="type[0]">
                                <b-col><label :for="`amount-${type[0]}`">{{ type[1] }}:</label></b-col>
                                <b-col><b-form-input type="number" v-model="orders[type[0]]" min="0" max="50"></b-form-input></b-col>
                            </b-row>
                        </b-card>
                        <b-card>
                            <b-row v-for="type in order.types.slice(16, 31)" :key="type[0]">
                                <b-col><label :for="`amount-${type[0]}`">{{ type[1] }}:</label></b-col>
                                <b-col><b-form-input type="number" v-model="orders[type[0]]" min="0" max="50"></b-form-input></b-col>
                            </b-row>
                        </b-card>
                        <b-card>
                            <b-row v-for="type in order.types.slice(31)" :key="type[0]">
                                <b-col><label :for="`amount-${type[0]}`">{{ type[1] }}:</label></b-col>
                                <b-col><b-form-input type="number" v-model="orders[type[0]]" min="0" max="50"></b-form-input></b-col>
                            </b-row>
                        </b-card>
                    </b-card-group>

                    <b-alert show variant="secondary">
                        <b>Gesamt:</b> {{ order.labels }} Etiketten auf {{ order.pages }} Seiten.
                    </b-alert>

                    <b-form-group>
                        <b-form-radio-group v-model="order.mode" id="orderOptions" name="orderOptions" stacked>
                            <b-form-radio value="print">
                                Selbst ausdrucken
                            </b-form-radio>
                            <b-form-radio value="pickup" :disabled="order.pages > 35">
                                <b>3,50€</b>: Abholung im Kreisverband Neu-Ulm (maximal 26 Seiten)
                            </b-form-radio>
                            <b-form-radio value="deliver" :disabled="order.pages > 35">
                                <b>5€</b>: Per Post versenden (maximal 26 Seiten)
                            </b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>

                    <b-form-group v-show="order.mode === 'pickup'">
                        <b>Öffnungszeiten:</b>

                        <p>
                            <small>
                                Bayerisches Rotes Kreuz Kreisverband Neu-Ulm<br />
                                Pfaffenweg 42, 89231 Neu-Ulm<br /><br />
                                Öffnungszeiten: Mo-Do 08:00-12:00 und 13:00-16:00<br />
                                Freitag 08:00-12:00
                            </small>
                        </p>
                    </b-form-group>

                    <b-form-group v-show="order.mode === 'deliver'">
                        <b>Versandadresse:</b>

                        <b-form-textarea id="orderAddress"
                                         v-model="order.address"
                                         placeholder="Versandadresse"
                                         :rows="3" :max-rows="6">
                        </b-form-textarea>
                    </b-form-group>

                    <b-button type="submit" variant="primary" :disabled="order.processing">
                        <font-awesome-icon :icon="faShoppingCart" v-show="!order.processing && order.mode !== 'print'" />
                        <font-awesome-icon :icon="faFilePdf" v-show="!order.processing && order.mode === 'print'" />
                        <font-awesome-icon :icon="faHourglass" v-show="order.processing" />
                        {{ order.processing ? 'Etikettenbogen wird generiert...' : order.mode === 'print' ? 'Etikettenbogen generieren' : 'Etikettenbogen bestellen' }}
                    </b-button>
                </b-form>
            </b-card>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { faHourglass, faShoppingCart, faFilePdf } from '@fortawesome/free-solid-svg-icons';

export default {
    async mounted() {
        if (this.$store.getters.isAuthenticated) {
            try {
                this.config = (await axios.get('/config')).data;
                this.confirm = (await axios.get('/user')).data;
            } catch {
                this.$store.dispatch('logout');
            }
        }
    },

    async beforeUpdate() {
        if (this.$store.getters.isAuthenticated && this.config.name === '') {
            try {
                this.config = (await axios.get('/config')).data;
                this.confirm = (await axios.get('/user')).data;
            } catch {
                this.$store.dispatch('logout');
            }
        }
    },

    data () {
        return {
            faHourglass,
            faShoppingCart,
            faFilePdf,

            config: {
                name: '',
                description: ''
            },
            form: {
                firstname: '',
                lastname: '',
                phone: '',
                mail: '',
                desiredNumber: '',
                consent: [],
                help: [],
            },
            confirm: {
                firstname: '',
                lastname: '',
                phone: '',
                mail: '',
                number: '',
                consent: [],
                help: [],
                previousNumber: undefined,
            },
            orders: {
                '005': 40,
                '010': 40,
                '015': 40,
                '020': 40,
                '025': 40,
                '030': 40,
                '035': 30,
                '040': 30,
                '045': 30,
                '050': 30,
                '060': 20,
                '070': 20,
                '080': 20,
                '090': 20,
                '100': 20,
                '120': 0,
                '140': 10,
                '150': 10,
                '160': 10,
                '180': 10,
                '190': 10,
                '200': 10,
                '220': 10,
                '250': 10,
                '290': 10,
                '350': 10,
                '390': 10,
                '450': 10,
                '490': 5,
                '550': 5,
                '590': 5,
                '650': 3,
                '690': 3,
                '750': 3,
                '790': 2,
                '850': 2,
                '890': 2,
                '950': 2,
                '990': 2
            },
            order: {
                types: [
                    ['005', ' 0,50 €'],
                    ['010', ' 1,00 €'],
                    ['015', ' 1,50 €'],
                    ['020', ' 2,00 €'],
                    ['025', ' 2,50 €'],
                    ['030', ' 3,00 €'],
                    ['035', ' 3,50 €'],
                    ['040', ' 4,00 €'],
                    ['045', ' 4,50 €'],
                    ['050', ' 5,00 €'],
                    ['060', ' 6,00 €'],
                    ['070', ' 7,00 €'],
                    ['080', ' 8,00 €'],
                    ['090', ' 9,00 €'],
                    ['100', '10,00 €'],
                    ['120', '12,00 €'],
                    ['140', '14,00 €'],
                    ['150', '15,00 €'],
                    ['160', '16,00 €'],
                    ['180', '18,00 €'],
                    ['190', '19,00 €'],
                    ['200', '20,00 €'],
                    ['220', '22,00 €'],
                    ['250', '25,00 €'],
                    ['290', '29,00 €'],
                    ['350', '35,00 €'],
                    ['390', '39,00 €'],
                    ['450', '45,00 €'],
                    ['490', '49,00 €'],
                    ['550', '55,00 €'],
                    ['590', '59,00 €'],
                    ['650', '65,00 €'],
                    ['690', '69,00 €'],
                    ['750', '75,00 €'],
                    ['790', '79,00 €'],
                    ['850', '85,00 €'],
                    ['890', '89,00 €'],
                    ['950', '95,00 €'],
                    ['990', '99,00 €']
                ],
                labels: 630,
                pages: 26,
                mode: 'print',
                success: false,
                processing: false,
                url: ''
            },
            show: true,
            success: false,
            error: false,
            confirmError: false,
            errors: []
        }
    },

    // computed: mapGetters([ 'isAuthenticated', 'session' ]),
    computed: mapGetters([ 'isAuthenticated', 'hasCapability', 'session' ]),

    watch: {
        orders: {
            handler(after) {
                this.order.labels = Object.values(after).map(c => c === '' ? 0 : c).reduce((p, c) => p + parseInt(c, 10), 0);
                this.order.pages = Math.ceil(this.order.labels / 24);

                if (this.order.pages > 35) {
                    this.order.mode = 'print';
                }
            },
            deep: true
        }
    },


    methods: {
        async onSubmit(evt) {
            evt.preventDefault();

            try {
                await axios.post('/signup', this.form);
                this.success = true;
                this.show = false;
            } catch(error) {
                this.error = true;
                this.errors = error.response.data.errors;
            }
        },
        async onConfirm(evt) {
            evt.preventDefault();

            try {
                this.confirm = (await axios.post('/confirm', this.confirm)).data;
                this.confirmError = false;

                localStorage.auth = this.confirm.token;
                axios.defaults.headers.common = { 'Authorization': `bearer ${this.confirm.token}` };
            } catch(error) {
                this.confirmError = true;
                this.errors = error.response.data.errors;
            }
        },
        async onOrder(evt) {
            evt.preventDefault();

            try {
                this.order.processing = true;
                const res = (await axios.post('/barcodes.pdf', {
                    order: this.orders,
                    mode: this.order.mode,
                    address: this.order.address
                }, { responseType: 'arraybuffer' }));

                this.order.success = true;
                if (res.headers['content-type'] === 'application/pdf') {
                    this.order.url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
                }
            } catch(error) {
                alert("Bei der Etikettengenerierung ist etwas schief gelaufen. Bitte versuchen Sie es später noch einmal.");
            }
        },
    }
}
</script>
