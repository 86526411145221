var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h5',[_vm._v("Herzlich Willkommen beim Kinderkleiderbasar der BRK Bereitschaftsjugend Neu-Ulm.")]),_vm._m(0),_c('p',[_vm._v("Mit unserem "),_c('router-link',{attrs:{"to":"/"}},[_vm._v("Anmeldeformular")]),_vm._v(" bekommen Sie eine Verkaufsnummer oder können sich als Helfer eintragen. Gerne dürfen Sie uns Ihre Wunschverkaufsnummer mitteilen und wir versuchen diese für Sie zu reservieren. Füllen Sie einfach unser Anmeldeformular aus und erhalten Sie alle weiteren Informationen sowie Ihre Etiketten. Da unser Kinderkleiderbasar zweimal im Jahr von vielen freiwilligen Helfern organisiert wird, freuen wir uns jederzeit über Unterstützung. Bei uns findet jeder eine Aufgabe die ihm Spaß macht. Wir freuen uns auf Sie.")],1),_vm._m(1),_vm._m(2),_vm._m(3),_c('p',[_vm._v("Schwangere (mit Mutterpass) und Rollstuhlfahrer dürfen bei uns, gemeinsam mit maximal einer Begleitperson, ab 13:30 Uhr einkaufen.")]),_vm._m(4),_vm._m(5),_c('p',[_vm._v("Ort: Mehrzweckhalle Ludwigsfeld, Karlsbaderstraße 23, 89231 Neu-Ulm")]),_vm._m(6),_vm._m(7),_c('p',[_vm._v("Damit möglichst viele Verkäufer*innen eine Chance erhalten Ihre Ware zu verkaufen können pro Verkäufer*in nur 2 Kartons, Körbe oder Taschen abgegeben werden.")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Der nächster Kinderkleiderbasar ist am "),_c('b',[_vm._v("16.03.2025")]),_vm._v(".")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Handgeschriebene Etiketten ohne Barcode können nicht mehr angenommen werden (außer Preise über 100€)!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('u',[_vm._v("Wie funktionieren die Barcode-Etiketten?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Bitte drucken Sie sich die benötigten Etiketten auf "),_c('b',[_vm._v("dickes weißes")]),_vm._v(" Papier aus. Sie können die Etiketten auch auf normales Papier drucken und auf Pappe kleben (hier eignen sich auch z.B. alte Cornflakes Schachteln etc.)")]),_c('li',[_c('b',[_vm._v("Alternativ, können Sie für den Umkostenbeitrag von 5 € die Etiketten bei uns bestellen und per Post nach Hause geliefert bekommen, bzw. für 3,50 € diese bei uns abholen.")])]),_c('li',[_vm._v("Den benötigten Preis können Sie dann einfach ausschneiden und die Größe ergänzen.")]),_c('li',[_vm._v("Dann befestigen Sie das Etikett fest am Artikel (nicht kleben).")]),_c('li',[_vm._v("Fertig - einfach und schnell!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Wir wünschen Ihnen viel Spaß bei unserem Basar."),_c('br'),_vm._v("Das gesamte Basarteam")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Abgabe: Samstag, 15.03.2025 14:00 - 17:00 Uhr"),_c('br'),_vm._v(" Verkauf: Sonntag, 16.03.2025 14:00 - 16:00 Uhr"),_c('br'),_vm._v(" Abholung: Sonntag, 16.03.2025 18:30 - 19:00 Uhr ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Verkauft wird:")]),_c('ol',[_c('li',[_vm._v("Saisonale Kinderbekleidung bis Größe 176 (Frühjahr und Sommer)")]),_c('li',[_vm._v("Kinderschuhe, 5 Paar in gutem Zustand")]),_c('li',[_vm._v("Vollständige, ganze Spielsachen")]),_c('li',[_vm._v("Kinderbücher oder Ratgeber für Eltern")]),_c('li',[_vm._v("Schwangerschaftsbekleidung")]),_c('li',[_vm._v("Fahrräder, Kinderfahrzeuge, Kinderwagen, Autositze, …")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Bitte beachten Sie folgendes:")]),_c('ol',[_c('li',[_vm._v("Geben Sie nur Ware ab, die Sie selbst noch kaufen würden.")]),_c('li',[_vm._v("Defekte Artikel erhalten Sie zurück.")]),_c('li',[_vm._v("Befestigen Sie Ihre Etiketten sicher an der Ware, ohne diese zu beschädigen.")]),_c('li',[_vm._v("Bitte verzichten Sie auf Nadeln, damit sich niemand verletzt.")]),_c('li',[_vm._v("Bitte beschriften Sie Ihren Karton/Tasche ebenfalls mit der Verkaufsnummer.")])])])
}]

export { render, staticRenderFns }